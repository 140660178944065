/* TransactionsComponent.css */
.TransactionsComponent {
    padding: 20px;
}

.transactions-page {
    display: flex;
    height: 100vh;
}

/* Add more styles as needed */
