.LeftListViewComponent {
}

.custom-sidebar-list-item {
    cursor: pointer
}

/* LeftListViewComponent.css */

.tabbed-content {
    height: auto; /* Auto height, will adjust based on content */
    max-height: 100vh; /* Maximum height set to 100% of the viewport height */
    overflow-y: auto; /* Enable scrollbar only if content exceeds the max height */
}

.list-group {
    max-height: calc(80vh - 60px); /* Adjust the value based on your header's height */
    overflow-y: auto; /* Enable scrollbar only if content exceeds the max height */
}

.custom-sidebar-list-item {
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.custom-sidebar-list-item:hover {
    background-color: #f8f9fa;
}

.custom-sidebar-list-item.active {
    background-color: #007bff;
    color: #fff;
}

/* Add any other styles as needed */
