.App {
    text-align: center;
    background-color: var(--background-color);
    color: var(--primary-color);

    --small-font:12px;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

:root {
    --primary-color: #E26313;
    --secondary-color: #232E44;
    --tertiary-color: #232E44;
    --background-color: #F9F9F8;
    --card-bg: #F9F9F8;
}

[data-theme="dark"] {
    --primary-color: #E27429;
    --secondary-color: #F9F9F8;
    --tertiary-color: #232E44;
    --background-color: #0F0E17;
    --card-bg: #232E44;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
