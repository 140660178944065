/* TransactionTable.css */

.transaction-table {
    width: 100%;
    overflow-x: auto;
}

.table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
}

.table th, .table td {
    padding: 8px;
    text-align: left;
}

.table th {
    background-color: #f2f2f2;
}

.table td {
    border-top: 1px solid #ddd;
}

.table th:nth-child(4), .table td:nth-child(4) {
    width: 30%;
    max-width: 30%;
}

.table th:nth-child(5), .table td:nth-child(5) {
    width: 25%;
    max-width: 25%;
}

.table th:nth-child(6), .table td:nth-child(6) {
    width: 10%;
}

/* Add line breakers for tags */
.badge {
    margin-bottom: 4px;
    word-break: break-all;
    max-width: 150px;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
}


.text-danger {
    color: red;
    font-weight: bolder;
}

.text-success {
    color: green;
    font-weight: bolder;
}
