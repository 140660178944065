/* TagsInputComponent.css */

.chips-container {
    display: flex;
    flex-wrap: wrap;
}

.chip {
    margin: 0.2rem;
    padding: 0.3rem 0.5rem;
    background-color: #007bff;
    color: #fff;
    border-radius: 0.2rem;
    display: flex;
    align-items: center;
}

.chip button {
    margin-left: 0.5rem;
    background: none;
    border: none;
    color: #fff;
    cursor: pointer;
}

.custom-suggestion-chip {
    cursor: pointer;
    font-size: var(--small-font);
    background-color: transparent;
    color: var(--primary-color);
    border-radius: 25px;
    border: 1px solid var(--primary-color);
}

.custom-suggestion-chip button {
    color: var(--primary-color);
}

.tags-suggestions-div::-webkit-scrollbar {
    display: none;
}

.tags-suggestions-div {
    max-height: 150px;
    overflow-y: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.custom-well-container {
    padding: 10px;
}