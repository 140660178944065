/* Navbar */
.navbar {
    background-color: var(--background-color);
    color: var(--primary-color);
    transition: background-color 0.3s ease-in-out;
}

.navbar-scrolled {
    /*background-color: #ffffff;*/
}

.navbar-brand {
    color: #000000;
    font-weight: bold;
}

.navbar-nav .nav-link {
    color: #000000;
    font-weight: bold;
}

.navbar-nav .nav-link:hover,
.navbar-nav .nav-link:focus {
    color: #007bff;
}

.navbar-toggler {
    border: none;
}

.navbar-toggler:focus {
    outline: none;
}

.navbar-toggler-icon {
    background-image: url('data:image/svg+xml;charset=UTF-8,<svg viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg"><path stroke="rgba(0,0,0,.5)" stroke-width="2" stroke-linecap="round" stroke-miterlimit="10" d="M4 7h22M4 15h22M4 23h22"></path></svg>');
}

.btn-primary {
    background-color: #007bff;
    border-color: #007bff;
}

.btn-primary:hover,
.btn-primary:focus {
    background-color: #0056b3;
    border-color: #0056b3;
}

.btn-secondary {
    background-color: #e5e5e5;
    border-color: #e5e5e5;
    color: #000000;
}

.btn-secondary:hover,
.btn-secondary:focus {
    background-color: #d1d1d1;
    border-color: #d1d1d1;
}

.form-check-label {
    color: #000000;
}

.form-check-input:checked + .form-check-label::before {
    background-color: #007bff;
    border-color: #007bff;
}

@media (max-width: 991.98px) {
    .navbar {
        position: fixed;
        top: 0;
        width: 100%;
    }

    .navbar-toggler-icon {
        background-image: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30"><path stroke="rgba(0,0,0,.5)" stroke-width="2" stroke-linecap="round" stroke-miterlimit="10" d="M4 7h22M4 15h22M4 23h22"></path></svg>');
    }

    .collapse.navbar-collapse {
        background-color: #f5f5f5;
        padding: 15px;
        position: fixed;
        top: 56px;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;
        overflow-y: auto;
        max-height: calc(100vh - 56px);
        transition: transform 0.3s ease-in-out;
    }

    .collapse.navbar-collapse.show {
        transform: translateY(0%);
    }

    .navbar-nav .nav-link {
        color: var(--primary-color);
        display: block;
        margin-bottom: 10px;
    }

    .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
        background-image: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30"><path stroke="rgba(0,0,0,.5)" stroke-width="2" stroke-linecap="round" stroke-miterlimit="10" d="M7 7l16 16M23 7L7 23"></path></svg>');
    }
}


.google-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
    border-radius: 4px;
    background-color: #fff;
    color: #000;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    cursor: pointer;
    border: 1px solid #ddd;
}

.google-icon {
    width: 18px;
    height: 18px;
    margin-right: 8px;
    background-image: url('/public/assets/img/google.png'); /* Replace with the path to your Google logo image */
    background-size: cover;
}

/* Optional hover effect */
.google-button:hover {
    background-color: #ddd;
}
